import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'

import Logo from '../../../static/images/logo_primary.png'
import ExternalButton from '../Button/ExternalButton'
import { GiHamburger } from 'react-icons/gi'

import { useCycle } from 'framer-motion'
import { useDimensions } from './MobileMenu/use-dimensions'

import MobileMenu from './MobileMenu/MobileMenu'
import MenuContent from './MobileMenu/MenuContent'
import { links } from './constants'
import { getWhatsappLink } from '../whatsappUtil'

const NavBar = () => {
  const [navbarHeight, setNavbarHeight] = useState(0)
  const [scrolled, changeState] = useState(false)
  const navbar = useRef(null)

  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  useEffect(() => {
    const onScroll = () => {
      if (document.documentElement.scrollTop > 50 && !scrolled) {
        changeState(true)
      } else if (document.documentElement.scrollTop <= 50 && scrolled)
        changeState(false)
    }

    window.addEventListener('scroll', onScroll)

    setNavbarHeight(navbar.current.getBoundingClientRect().height)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [scrolled])

  return (
    <div
      className={`z-40 fixed w-full duration-300 transition-all nav ${
        scrolled ? 'p-4 bg-dark shadow-lg' : 'p-5 bg-transparent'
      }`}
    >
      <div
        className={`max-w-screen-lg mx-auto flex justify-between items-center my-auto `}
        ref={navbar}
      >
        <Link to="/">
          <img
            src={Logo}
            alt="Logo del fuego"
            className={`duration-300 transition-all ${
              scrolled ? 'w-16' : 'w-24'
            }`}
          />
        </Link>

        <MobileMenu
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          height={height}
          containerRef={containerRef}
        />

        <div className="hidden lg:block">
          <div className="flex justify-center items-center text-white">
            {links.map((link, index) => {
              {
                if (link.isExternal)
                  return (
                    <a
                      href={link.value}
                      target="_blank"
                      aria-label={link.value}
                      className="cool-link text-base"
                      key={index}
                      rel="noreferrer"
                    >
                      <p className="px-3">{link.name}</p>
                    </a>
                  )
              }
              {
                if (!link.isExternal)
                  return (
                    <Link
                      className="cool-link text-base"
                      key={index}
                      to={link.value}
                    >
                      <p className="px-3">{link.name}</p>
                    </Link>
                  )
              }
            })}
            <ExternalButton
              className="ml-4"
              title="Delivery"
              link={getWhatsappLink(
                '55999703260',
                'Olá, gostaria de fazer um pedido:'
              )}
              icon={<GiHamburger className="h-8 w-8 ml-2" />}
            />
            <ExternalButton
              className="ml-4 py-4"
              title="Seja um franqueado"
              link="https://carniceriadelfuegofranquia.com.br/"
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <MenuContent
          isOpen={isOpen}
          height={height}
          containerRef={containerRef}
          links={links}
        />
      )}
    </div>
  )
}

export default NavBar
