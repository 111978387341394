import React from 'react'

import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaWhatsapp
} from 'react-icons/fa'

import Separator from '../Menu/Separator/Separator'
import Div from '../Motion/Div/Div'
import { getWhatsappLink } from '../whatsappUtil'

const Contact = () => {
  const src =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d612.6598600894823!2d-53.79359034719049!3d-29.69867820135234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9503cbaf00f1c843%3A0x5a99b82d4c024919!2sCarniceria%20Del%20Fuego!5e0!3m2!1spt-BR!2sbr!4v1630786852936!5m2!1spt-BR!2sbr'
  return (
    <section id="contato" className="bg-dark">
      <Div className="flex flex-col max-w-screen-xl mx-auto md:flex-row py-10 px-5 justify-center items-center">
        <div className="flex flex-col flex-1 text-white text-base justify-center items-center mx-5 md:mx-0">
          <div>
            <h2 className="text-lg text-primary text-center py-2">
              Horários de atendimento
            </h2>
            <p className="mreaves text-base leading-8 text-center">
              De segunda a domingo <br />
              Almoço das 11:30 às 14:30 <br />
              Janta das 18:00 às 23:30 com consumo até 01:00 <br />
              Delivery até às 23h <br />
              Reservas até 19:30, depois liberamos as mesas por ordem de chegada{' '}
              <br />
            </p>
            <Separator className="my-4" style="solid" />
            <div className="flex flex-1 py-3 justify-between items-center">
              <FaMapMarkerAlt className="h-8 w-8 mr-5" />
              <h3 className="mreaves flex-1 text-center">
                Fernando Ferrari, n°1760, Santa Maria - RS
              </h3>
            </div>
            <div className="flex flex-1 py-3 justify-between items-center">
              <FaPhoneAlt className="h-8 w-8 mr-5" />
              <h3 className="mreaves flex-1 text-center">
                (55) 3029-1267 / 9 9970-3260
              </h3>
            </div>
            <div className="flex flex-1 py-3 justify-between items-center">
              <FaEnvelope className="h-8 w-8 mr-5" />
              <h3 className="mreaves flex-1 text-center">
                carniceriadelfuego@gmail.com
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex flex-1 py-3 justify-center items-center">
                <a
                  href="https://www.facebook.com/carniceriadelfuego"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebook className="h-8 w-8 mr-5 hover:text-primary" />
                </a>
                <a
                  href="https://www.instagram.com/carniceriadelfuego/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram className="h-8 w-8 mr-5 hover:text-primary" />
                </a>
                <a
                  href={getWhatsappLink('55999703260', 'Olá')}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="WhatsApp"
                >
                  <FaWhatsapp className="h-8 w-8 hover:text-primary" />
                </a>
              </div>
              <p className="mreaves">Vamos amar se você nos seguir</p>
              <p>@carniceriadelfuego</p>
            </div>
          </div>
        </div>
        <div className="flex-1 p-5">
          <iframe
            src={src}
            className="rounded-lg shadow-md"
            width="100%"
            height="400px"
            frameBorder="0"
            title="Endereço Carniceria Del Fuego"
          />
        </div>
      </Div>
    </section>
  )
}
export default Contact
